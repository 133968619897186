html,
body,
#root {
  margin: 0;
  padding: 0;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  font-family: Roboto, "Helvetica Neue", "Segoe Ui", Arial, Helvetica,
    sans-serif;
}

* {
  box-sizing: border-box;
}
